import React, { useContext } from 'react'
import { GlobalDataContext } from "../../context/context";
import { ButtonContent } from './boton/ButtonContent';



const CounterUp = ({ image }) => {

    const { rpdata } = useContext(GlobalDataContext);

    return (
        <div className='bgCountent' style={{ backgroundImage: `url("${image}")` }}>
            <img
                src={"https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/Restaurante%2FSlice%20Top.png?alt=media&token=2de29ecf-9425-4778-9606-0a59172af59d"}
                alt='no found'
                loading='lazy'
                className='absolute -top-2 left-0 w-[100%] h-[6%]'
            />

            <img
                src={"https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/Restaurante%2FSlice%20Top.png?alt=media&token=2de29ecf-9425-4778-9606-0a59172af59d"}
                alt='no found'
                loading='lazy'
                className='absolute -bottom-2 left-0 w-[100%] h-[50px] scale-y-[-1]'
            />
            <h6 className='text-center text-[30px] font-bold relative text-[#90dd00]'>It's Hot!</h6>
            <img className="w-[50px] h-[20px] relative mx-auto"
                src="https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/Restaurante%2FMY.png?alt=media&token=f1c4c328-7180-4876-88ee-371d43c13aaf"
                alt="bite" />
            <h2 className='text-center py-4 md:text-[60px] text-[40px] font-extrabold relative text-white px-5 md:px-[20%] uppercase'>{rpdata?.dbSlogan?.[4].slogan}</h2>
            <div className='w-4/5 mx-auto grid grid-cols-1'>
                <div className='flex-col flex items-center justify-center '>
                    <p className='text-white relative text-center'>
                        {
                            rpdata?.dbHome?.[1]?.text.substring(0,200)
                        }
                    </p>

                    <ButtonContent btnStyle="six" />
                </div>
            </div>
        </div>
    )
}
export default CounterUp
