import { useContext } from "react";
import { GlobalDataContext } from '../../context/context';
import ServicesHome from '../Home/ServiceCardHome'

const BlockServ = () => {
    const { rpdata } = useContext(GlobalDataContext);

    return (
        <section className="flex w-full flex-col items-center justify-center">
            <div className="flex items-center gap-x-5">
                <img src="https://acortar.link/9gTWpM" alt="ms" className="w-[60px] h-[30px] scale-x-[-1]" />
                <h5 className="text-[#90dd00]">Hello!</h5>
                <img src="https://acortar.link/9gTWpM" alt="ms" className="w-[60px] h-[30px]" />
            </div>
            <div>
                <h2 className="font-bold text-[#613e3e] uppercase text-center text-[38px]">{rpdata?.dbSlogan?.[1]?.slogan}</h2>
            </div>
            <section className="flex flex-wrap items-center justify-center md:space-x-5 space-x-0 space-y-5 md:space-y-0 my-10">
                {rpdata?.dbServices?.slice(0, 3).map((item, index) => {
                    return (
                        <ServicesHome
                            index={index}
                            key={index}
                            bgImg={item.description[0].img}
                            serviceName={item.name}
                            serviceText={item.description[0].text.substring(0, 160) + "..."}
                        />
                    );
                })}
            </section>

        </section>
    );
}

export default BlockServ;