import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
import ContentMenu from "./ContentMenu";
function ContentServices() {
  const { rpdata } = useContext(GlobalDataContext);

  return (
    <>
      <div className="w-full relative">

        <div className="mainMenu">
          <h2 className="text-center mt-10 md:mb-[-100px] mb-[-150px] font-bold relative" >{rpdata?.labels?.general?.titleServices}</h2>
          <div className="py-40 w-4/5 mx-auto relative">
            <ContentMenu />

          </div>

        </div>

      </div>
    </>
  );
}
export default ContentServices;