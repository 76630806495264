import React, { useContext, useState } from 'react'
import { GlobalDataContext } from '../../../context/context';
import { Link } from 'react-router-dom'
import MenuList from '../MenuList';
import { ButtonContent } from '../boton/ButtonContent';
import { GiHamburgerMenu } from 'react-icons/gi';
import { HiOutlineViewGrid, HiQuestionMarkCircle } from "react-icons/hi";
import { FaEnvelopeOpenText } from "react-icons/fa";
import { BiCabinet } from "react-icons/bi";
import { BsPlusLg } from 'react-icons/bs';
import IconsRedes from "../IconsRedes";


const HeaderOne = () => {
    const { rpdata } = useContext(GlobalDataContext);
    const [offCanvas, setOffCanvas] = useState(false);
    const [subMenu, setSubMenu] = useState(false);

    //Recorrer el array de los servicios
    const subitems = rpdata?.dbServices?.map((item) => {
        return {
            link: `/${item.name.replaceAll(" ", "-").toLowerCase()}`,
            name: item.name,
        };
    });

    //sub menu
    const subitemsGallery = rpdata?.landings?.map((itemsGallery) => {
        return {
            link: `/gallery/${itemsGallery.name.replaceAll(" ", "-").toLowerCase()}`,
            name: itemsGallery.name,
        };
    });
    // menu



    const Navigation = [
        {
            name: "Home",
            path: "/",
            icon: <HiOutlineViewGrid className="mx-auto text-[20px]" />,
        },
        {
            name: "About",
            path: "/about",
            icon: <HiQuestionMarkCircle className="mx-auto text-[20px]" />,
        },
        {
            name: "Services",
            path: "/services",
            icon: <BiCabinet className="mx-auto text-[20px]" />,
            child: rpdata?.autoGntLandingFromService,
            submenu: [...(subitems ? subitems : [])],
        },
        {
            name: `Gallery`,
            path: `/gallery`,
            icon: <BiCabinet className="mx-auto text-[20px]" />,
            child: rpdata?.customLinks,
            submenu: [...(subitemsGallery ? subitemsGallery : [])],
        },
        {
            name: "Contact",
            path: "/contact",
            icon: <FaEnvelopeOpenText className="mx-auto text-[20px]" />,
        },
    ];


    return (
        <div>
            {
                rpdata?.simpleWidgets?.[3]?.activo ?
                    <div className='absolute right-[5%] left-[5%] z-50 pt-2  flex flex-col justify-center items-center'>
                        <div className="md:w-full md:flex justify-center items-center relative text-black">
                            <ul className="flex justify-center space-x-4 pb-2 md:pb-0">
                                <li className=" md:text-[17px] text-[13px] hidden md:block ">
                                    <span className="border-r-[1px] border-black pr-3">

                                        {rpdata?.dbPrincipal?.location[0]?.address}
                                    </span>
                                </li>
                                <li className="md:text-[17px] text-[13px] ">
                                    <span className=" pr-3">

                                        {rpdata?.dbPrincipal?.workdays[0]?.day}
                                    </span>
                                </li>
                                <li className="md:text-[17px] text-[13px] ">
                                    <span className="border-r-[1px] border-black pr-3">

                                        {rpdata?.dbPrincipal?.workHours[0]?.hour}
                                    </span>
                                </li>

                                <li className="md:text-[17px] text-[13px] ">
                                    <span className="pr-3">

                                        {rpdata?.dbPrincipal?.workdays[1]?.day}
                                    </span>
                                </li>
                                <li className="md:text-[17px] text-[13px] ">
                                    <span className="pr-3">

                                        {rpdata?.dbPrincipal?.workHours[1]?.hour}
                                    </span>
                                </li>
                            </ul>
                           
                        </div>
                        {/* logo */}
                        <div className='w-[70%] md:w-[30%] pr-0'>
                            <Link to={'/'}>
                                <img
                                    src={`${rpdata?.dbPrincipal?.logo}`}
                                    alt='no found'
                                    className='w-full'
                                />
                            </Link>
                        </div>
                    </div>
                    :


                    <div className='w-full bg-white z-10 pt-3 flex justify-center items-center'>
                        <div className='w-[80%] flex justify-between items-center'>
                            {/* logo */}
                            <div className='w-[100%] md:w-[20%] pr-3 md:pr-0'>
                                <Link to={'/'}>
                                    <img
                                        src={`${rpdata?.dbPrincipal?.logo}`}
                                        alt='no found'
                                        className='w-full'
                                    />
                                </Link>
                            </div>
                            {/* nav menu */}
                            <nav className='w-[20%] md:w-[80%] md:flex hidden items-center justify-end'>
                                <div className='mr-2'>
                                    <MenuList classes={"text-[#613e3e] space-x-3 block items-center"} />
                                </div>
                                <ButtonContent btnStyle={"six"} btnName={"Call Us Now"} />
                            </nav>

                            {/* nav menu mobil */}



                            <div className="flex self-center md:hidden w-[30%] justify-center">
                                <GiHamburgerMenu
                                    className="text-[40px] textColor"
                                    onClick={() => {
                                        setOffCanvas(!offCanvas);
                                    }}
                                />
                            </div>


                        </div>
                    </div>
            }

            <nav>
                {offCanvas ? (
                    <nav className="side-slide z-10">
                        <button
                            className="text-white relative top-[-30px] left-[210px] bg2 py-[6px] px-[15px] rounded-md"
                            onClick={() => {
                                setOffCanvas(false);
                            }}
                        >
                            x
                        </button>
                        <div>
                            <img
                                src={rpdata?.dbPrincipal?.logo}
                                alt="Company Logo"
                                className="w-[80%] mb-8"
                            />
                        </div>
                        <div>
                            <ul className="bg-navbar-movil">
                                {Navigation.map((item, index) => {
                                    return (
                                        <li key={index}>
                                            <Link
                                                to={item.child ? "" : item.path}
                                                className="flex self-center items-center"
                                                onClick={() => {
                                                    setSubMenu(!subMenu);
                                                }}
                                            >
                                                {item.name}
                                                {item.child ? <BsPlusLg className="ml-[140px]" /> : null}
                                            </Link>
                                            {item.child ? (
                                                <ul className="rounded-sm w-[200px] text-white relative ml-2 off-canvas">
                                                    {item.submenu.map((item, index) => {
                                                        return (
                                                            <li
                                                                key={index}
                                                                className="py-2 px-4"
                                                                onClick={() => {
                                                                    setOffCanvas(false);
                                                                }}
                                                            >
                                                                <Link to={item.link} exact>
                                                                    {item.name}
                                                                </Link>
                                                            </li>
                                                        );
                                                    })}
                                                </ul>
                                            ) : null}
                                        </li>
                                    );
                                })}
                            </ul>
                            <div>
                                <IconsRedes classes={'flex justify-center items-center gap-5'} />
                            </div>
                        </div>
                    </nav>
                ) : null}
            </nav>
        </div>
    )
}

export default HeaderOne