import React from "react";
import { GiNachos, GiTacos } from "react-icons/gi";



const ServiceCardHome = ({ bgImg, serviceName, index }) => {



    return (
       
            <article className="w-[350px] md:w-[400px] md:h-[400px]  h-[350px] hover:scale-105 transition-all delay-75 duration-150 ease-in  bgcard p-5">
                <div className="w-full h-full bg-cover relative bg-center before:bg-[#13131383]  before:absolute before:inset-0 " style={{ backgroundImage: `url("${bgImg}")` }}>
                    <div className="mx-auto flex flex-col md:space-y-2 space-y-0 items-center h-full justify-center relative text-white">
                        {
                            index % 2 === 0 ? <GiNachos fontSize={80} className="text-white rounded-full p-4" /> : <GiTacos fontSize={80} className="text-white rounded-full p-4" />
                        }
                        <h3>
                            {serviceName}
                        </h3>
                        {/* <p>
                        {serviceText}
                    </p> */}

                        <img src="https://acortar.link/kpohIV" alt="cornb" className="w-[120px] h-[30px]" />
                    </div>
                </div>
            </article>
     

    );
}

export default ServiceCardHome;