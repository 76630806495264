import React, { useContext } from 'react'
import { GlobalDataContext } from '../../context/context'
import { ButtonContent } from '../global/boton/ButtonContent';

const ContentMenu = (cntService) => {
  const { rpdata } = useContext(GlobalDataContext);

  let classAnterior = "md:flex-row-reverse"

  return (
    <div className=''>
      {
        rpdata?.dbServices?.map((serv, index) => {
          let classes = index % 2 === 0 ? "md:flex-row-reverse" : "md:flex-row"
          let nuevaClass = classAnterior === "md:flex-row-reverse" ? "md:flex-row" : "md:flex-row-reverse"
          classes = nuevaClass
          classAnterior = classes;
          return (
            <div className='pb-20'>
              <div key={index} className={`pb-3 mb-10 border-b-2 flex flex-col-reverse justify-between items-center ${classes}`}>
                <div>
                  <h3 className='text-center capitalize textColor1'>{serv.name}</h3>
                  <h5 className='font-medium pl-7'>{serv?.description[0]?.text}</h5>
                  <ButtonContent btnLink='./Contact' btnName={'Order Now'} />
                </div>

                <img
                  src={serv?.description[0]?.img}
                  alt='services images'
                  className='w-[150px] h-[150px] rounded-full shadow-lg'

                />
              </div>
              <div>
                {
                  rpdata?.works?.map((item, index) => {
                    if (item.service === serv.name) {
                      return (
                        <div key={index} className='w-[90%] mx-auto flex items-center'>
                          <div className='w-full'>
                            <p className='w-[75%] md:w-[80%] border-dotted border-b-2 capitalize font-semibold text-[18px]'>{item.name}</p>
                            <p className='w-[100%] md:w-[50%] text-start md:text-start text-[#8E101F] font-semibold'> {item.description}</p>
                          </div>
                          <p className='w-[75%] md:w-[20%] text-end md:text-start text-[#8E101F] font-semibold mb-5'> {item.subdescription}</p>

                        </div>
                      )
                    }
                    return null
                  })
                }
              </div>
            </div>
          )
        })
      }
    </div>
  )
}

export default ContentMenu